.footer_main_div {
  width: 100%;
  background-image: url(./../../Assets/scene.png);
  height: 27vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer_flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 4vw 5vw;
}

.footer_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vw;
  flex-direction: column;
}

.convenience_text {
  color: #0e0e10;
  text-align: center;
  font-family: DM Serif Display;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.16px;
}

.easy_selling {
  color: #0e0e10;
  text-align: center;
  font-family: sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.56px;
}

.convenience_btn {
  font-size: 0.8vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13vw;
  height: 2.8vw;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #194668;
  background: linear-gradient(
    180deg,
    #194668 14.58%,
    rgba(127, 161, 186, 0) 85.42%
  );
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
}

.contact_line {
  color: #194668;
  text-align: center;
  font-family: "Poppins";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.56px;
}

.numm {
  font-weight: 700;
}
