.hundred_width_div {
  width: 100% !important;
  height: 70vw;
  background: rgba(25, 70, 104, 0.26);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.manual_width_div {
  margin-top: -10%;
  width: 65% !important;
  display: flex;
  flex-direction: column;
  gap: 4vw;
  /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
}

.comparison_heading {
  color: #000;
  text-align: center;
  font-family: DM Serif Display;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4vw;
  text-align: center;
}

.row_class {
  border-radius: 1.5vw !important;
  border: 1px solid #194668;
  background: rgba(25, 70, 104, 0.35);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
  padding: 0px !important;
  margin: 0px !important;
  /* margin-top: -2vw !important; */
}

.left_column {
  /* border-radius: 30px 0px 0px 30px; */

  padding: 0px !important;
  margin: 0px !important;
}

.first_left {
  background: #194668cc;
  height: 3.7vw;
  border-top-left-radius: 1.5vw;
}

.logo_divv {
  background-color: #194668;
  height: 3.7vw;
}

.imgg_cls {
  width: 7vw;
  margin-top: -1.2vw !important;
  margin-left: 6vw;
}

.center_column {
  padding: 0px !important;
}

.right_column {
  padding: 0px !important;
  margin: 0px !important;
}

.first_right {
  background-color: #194668;
  height: 3.7vw;
  width: 99%;
  border-top-right-radius: 2.5vw;
  border-bottom-right-radius: 2.5vw;
  display: flex;
  align-items: center;

  color: #fff;
  text-align: center;
  font-family: sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.left_column_text {
  background: rgba(25, 70, 104, 0.8);
  display: flex;
  align-items: center;
  color: #dfdfdf;
  height: 3.5vw;
  font-family: sans-serif;
  padding-left: 2vw;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}

.center_column_text {
  display: flex !important;
  align-items: center !important;
  height: 3.5vw;
  padding-left: 3vw;

  color: #fff;
  font-family: sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tick_img {
  width: 1.8vw;
  height: 1.8vw;
  aspect-ratio: 1/1;
  margin-right: 1vw;
}

.right_column_text {
  display: flex !important;
  align-items: center !important;
  height: 3.5vw;
  padding-left: 1vw;

  color: #fff;
  font-family: sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cross_img {
  width: 1.8vw;
  height: 1.8vw;
  aspect-ratio: 1/1;
  margin-right: 1vw;
}

.last_left_cell {
  border-bottom-left-radius: 1.5vw;
  border-bottom-right-radius: 1.5vw;
}

@media only screen and (max-width: 767px) {
  .hundred_width_div {
    width: 100% !important;
    height: 75vw !important;
    margin-top: 5vw !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .manual_width_div {
    width: 85% !important;
    margin-left: 4% !important;
    padding-left: 0px !important;
  }
  .row_class {
    /* display: none !important; */
    width: 100% !important;
  }

  .first_left {
    height: 4.5vw;
  }
  .logo_divv {
    height: 4.5vw;
  }

  .first_right {
    height: 4.5vw;
    padding-left: 4vw;
  }
  .left_column_text {
    height: 4.5vw;
    font-size: 1.3vw;
  }

  .center_column_text {
    height: 4.5vw;

    color: #fff;
    font-family: sans-serif;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .right_column_text {
    display: flex !important;
    align-items: center !important;
    height: 3.5vw;
    padding-left: 1vw;
    height: 4.5vw;
    color: #fff;
    font-family: sans-serif;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .comparison_pic_img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

 
}

@media only screen and (min-width: 768px) {
  .comparison_pic_img {
    display: none;
  }
}

.schedule_card {
  position: absolute;
  bottom: -7vw;
  border-radius: 1.5vw;
  border: 1px solid #194668;
  background: rgba(94, 126, 149, 0.5);
  width: 57vw;
  height: 15vw;
  display: flex;
  flex-direction: row;
  padding: 2vw;
  padding-right: 4vw;
  display: flex;
  justify-content: space-between;
}

.meeting_img {
  width: 13vw;
}

.schedule_left {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.schedule_text {
  color: #0e0e10;
  font-family: DM Serif Display;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 58px */
  letter-spacing: 1.16px;
}

.below_schedule_text {
  color: #0e0e10;
  font-family: sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.48px;
}

.call_us_text {
  color: #194668;
  font-family: Poppins;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

.numm {
  font-weight: 800 !important;
}

.schedule_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1vw; */
}

.meeting_btn {
  font-size: 0.7vw;
  color: #fff;
  width: 12vw;
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  border: 1px solid #194668;
  background: linear-gradient(
    180deg,
    #194668 14.58%,
    rgba(127, 161, 186, 0) 85.42%
  );
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 507px) {
  .imgg_cls {
    width: 7vw;
    margin-top: -3.2vw !important;
    margin-left: 6vw;
  }
}

@media only screen and (max-width: 340px) {
  .imgg_cls {
    width: 7vw;
    margin-top: -5.2vw !important;
    margin-left: 6vw;
  }
}
